@import "../../node_modules/bootstrap/scss/bootstrap";

html,
body {
  width:100%;
  height: 100% ;
  font-family: "HiraKakuPro-W3", "ヒラギノ角ゴ Pro W3", "hiragino kaku gothic pro w3", "游ゴシック", YuGothic,
    "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Arial, verdana, sans-serif;
}

#wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer{
  margin-top: auto;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.en {
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
}

.no-font{
  font-family: "HiraKakuPro-W3", "ヒラギノ角ゴ Pro W3", "hiragino kaku gothic pro w3", "游ゴシック", YuGothic,
  "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", Arial, verdana, sans-serif;
}

ul li {
  list-style: none;
}

hr {
  border-top: 1px solid #00495b;
}

#member.information hr{
   border-top: 1px solid #bfa45a; 
}

a{
transition: opacity 0.3s ease-out;
}

a:hover{
  text-decoration: none;
  opacity: 0.5;
}

@media (min-width: 993px) {
  .sp-view {
    display: none;
  }
}

@media (max-width: 992px) {
  .pc-view {
    display: none !important;
  }

  .mb-50 {
    margin-bottom: 30px;
  }

  .mb-30 {
    margin-bottom: 15px;
  }
}
